import './app.scss';
import Form from './form'

function App() {
  return (
    <div>
      <h1>Are you a Bad Bitch Developer Queen?</h1>
      <Form />
    </div>
  );
}

export default App;
